import { Component, forwardRef, Inject, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ModalController,
  ToastController,
} from '@ionic/angular';
import { Router } from '@angular/router';
import moment from 'moment';
import { finalize, take } from 'rxjs/operators';
import { GraphqlService } from 'src/app/core/graphql/graphql.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './new-dossier.component.html',
  styleUrls: ['./new-dossier.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => NewDossierComponent),
    },
  ],
})
export class NewDossierComponent implements OnInit {
  form: UntypedFormGroup;

  date: Date;

  isDateInvalid: boolean;

  isLoading = false;

  genders = [
    {
      label: "mGender",
      value: "m"
    },
    {
      label: "fGender",
      value: "f"
    },
    {
      label: "xGender",
      value: "x"
    },
  ]

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<NewDossierComponent>,
    private modalCtrl: ModalController,
    private _graphql: GraphqlService,
    private _translate: TranslateService,
    private toastCtrl: ToastController,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      gender: new UntypedFormControl('x'),
      birthdate: new UntypedFormControl(null, {
        validators: [Validators.required],
      }),
    });
  }

  async makeToast(header: string, message: string, color: string, duration = 2000) {
    const toast = await this.toastCtrl.create({
      color,
      header,
      message,
      duration: duration,
      position: 'bottom',
      buttons: [
        {
          text: "X",
          handler: () => {
            toast.dismiss()
          }
        }
      ]
    });
    toast.present();
  }

  checkDate() {
    const date = moment(this.form.value.birthdate);
    if (date.isValid() && date.isBefore()) {
      this.isDateInvalid = false;
      this.date = date.toDate();
      this.onCreate()
    } else {
      this.isDateInvalid = true;
    }
  }

  onCreate() {
    this.isLoading = true;
    this._graphql.createRecord(this.date.toISOString(), this.form.value.gender)
      .pipe(
        take(1),
        finalize(() => this.isLoading = false))
      .subscribe(({ data }: any) => {
        this.makeToast(this._translate.instant("addedRecord", { id: data.createRecord.record.recordNumber }), '', 'success', 0);
        this.form.reset();
        this.dialogRef.close();
        this.router.navigate([
          '/',
          'start',
          'doc',
          data.createRecord.record.id,
        ]);
      },
        (error) => {
          this.makeToast(`Une erreur est survenue: ${error}`, '', 'danger');
        }
      );
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
