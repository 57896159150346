import { Component, Inject, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
 MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import {  finalize, map, take } from 'rxjs/operators';
import { Record } from 'src/app/shared/models/record.model';
import { SharedService } from 'src/app/shared/services/shared.service';
import { GraphqlService } from 'src/app/core/graphql/graphql.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  templateUrl: './search-record.component.html',
  styleUrls: ['./search-record.component.scss'],
})
export class SearchDocComponent {
  myControl = new UntypedFormControl();

  isLoading = false;

  unarchiveLoading = false;

  searchValue: string = '';

  results: Record[] = null;

  etabId: string;

  userId: string;

  allDocs: Record[];

  allLoaded = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _graphql: GraphqlService,
    private _translate: TranslateService,
    public dialog: MatDialog,
    private sharedService: SharedService
  ) { }

  findRecords(e) {
    if (e.target.value === '') {
      this.results = null;
    } else {
      this.isLoading = true;
      this._graphql.findRecords(e.target.value, this.data.isArchived)
        .pipe(take(1),
          map(({ data }: any) => data.findRecords))
        .subscribe(
          (records: any) => {
            this.results = records;
            this.isLoading = false;
          },
          (error) => {
            this.sharedService.makeToast(
              this._translate.instant("errorOccured") + error,
              '',
              'danger'
            );
          }
        );
    }
  }

  unarchive(id: string){
    this.unarchiveLoading = true;
    this._graphql.unArchiveRecord(id)
    .pipe(
      take(1),
      finalize(()=> this.unarchiveLoading =false))
    .subscribe(_ => {
      this.results = this.results.filter(rec => rec.id != id);
      this._graphql.emptyCache()
    });
  }


  clearSearch() {
    this.results = null;
    this.searchValue = '';
  }

}
