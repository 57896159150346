<ion-content class="has-header" overflow-scroll="true">
  <ion-row style="position: relative; min-height: 100%">
    <ion-col
      style="margin-bottom: 80px"
      size="12"
      size-lg="10"
      offset-lg="1"
      size-xl="10"
      offset-xl="1"
    >
      <app-header title="umanweb" backTo="start" show="true"></app-header>
      <ion-progress-bar
        *ngIf="isLoading"
        type="indeterminate"
      ></ion-progress-bar>

      <div style="position: absolute; right: 32px; z-index: 5">
        <mat-icon
        color="accent"
        style="cursor: pointer;"
          (click)="onTuto()"
        >help</mat-icon>
      </div>

      <div *ngIf="!isLoading">
        <div class="flex-grid">
          <app-card
            color="#96552F"
            title="newRecord"
            icon="create_new_folder"
            iconOnly="true"
            button1="create"
            (button1Clicked)="newDossier()"
          ></app-card>
          <app-card
            [isDisabled]="isEmpty"
            color="#D8C4C4"
            title="existingRecords"
            icon="folder"
            iconOnly="true"
            button1="search"
            (button1Clicked)="onSearchRecord(false)"
          ></app-card>
          <app-card
            [isDisabled]="isArchivesEmpty"
            color="#D8C4C4"
            title="archivedRecords"
            icon="inbox"
            iconOnly="true"
            button1="search"
            (button1Clicked)="onSearchRecord(true)"
          ></app-card>
        </div>
        <div class="flex-grid">
          <app-card
            color="#2A435B"
            title="references"
            icon="auto_stories"
            iconOnly="true"
            button1="consult"
            (button1Clicked)="goToRef()"
          ></app-card>
          <app-card
            color="#EFEAE5"
            title="params"
            icon="settings"
            iconOnly="true"
            button1="handle"
            (button1Clicked)="goToParam()"
          ></app-card>
          <app-card
            color="#EFEAE5"
            title="statistics"
            icon="query_stats"
            iconOnly="true"
            button1="consult"
            (button1Clicked)="goToStats()"
          ></app-card>
        </div>
      </div>
    </ion-col>
    <app-footer></app-footer>
  </ion-row>
</ion-content>
