<div style="padding: 20px 24px;">
  <ion-row class="sticky">
    <ion-col size="2"></ion-col>

    <ion-col size="8">
      <h1 style="text-align: center; font-size: 26px;margin-top: 36px;">{{ (data.isArchived ? "archiveSearch" :
        "recordSearch") |
        translate |
        capitalizeFirstLetter }}</h1>
    </ion-col>
    <ion-col style="text-align: end" size="2">
      <ion-button mat-dialog-close fill="clear">
        <mat-icon>close</mat-icon>
      </ion-button>
    </ion-col>
  </ion-row>

  <mat-dialog-content>
    <ion-row>
      <ion-col size-md="8" offset-md="2">
        <ion-row style="place-content: center">
          <ion-col size="6" style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          ">
            <mat-form-field appearance="outline" color="accent" style="width: 240px">
              <mat-label>{{ "recordNumber" | translate | capitalizeFirstLetter }}</mat-label>
              <input autocomplete="off" (keyup)="findRecords($event)" matInput type="text" [(ngModel)]="searchValue" />
              <button mat-button *ngIf="searchValue" matSuffix mat-icon-button aria-label="Clear"
                (click)="clearSearch()">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

          </ion-col>
        </ion-row>
        <ion-progress-bar *ngIf="isLoading" type="indeterminate"></ion-progress-bar>
        <ion-row class="ion-justify-content-center">
          <ion-col size="6" style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          ">
            <div style="width: 240px">
              <mat-selection-list *ngIf="results && !data.isArchived" [multiple]="false" hideSingleSelectionIndicator>
                <mat-list-option mat-dialog-close [routerLink]="['/', 'start','doc', record.id]"
                  [queryParams]="{etabId: data.etabId}" *ngFor="let record of results">
                  <mat-icon matListItemIcon style="color: black;">folder</mat-icon>
                  <div matListItemTitle>{{ "record" | translate | capitalizeFirstLetter }} {{ record.recordNumber }}</div>
                </mat-list-option>
              </mat-selection-list>

              <mat-list *ngIf="results && data.isArchived" [multiple]="false">
                <mat-list-item *ngFor="let record of results">
                  <button matListItemMeta mat-flat-button color="accent" (click)="unarchive(record.id)" style="color: white; font-size: 14px;">
                    <span *ngIf="!unarchiveLoading">{{ "unarchive" | translate | capitalizeFirstLetter }}</span>
                    <mat-progress-spinner style="display: block; margin-left: auto; margin-right: auto;"
                      *ngIf="unarchiveLoading" [diameter]="24" [mode]="'indeterminate'" color="primary">
                    </mat-progress-spinner>
                  </button>
                  <div matListItemTitle>{{ "record" | translate | capitalizeFirstLetter }} {{ record.recordNumber }}</div>
                </mat-list-item>
              </mat-list>
              <div style="text-align: center" *ngIf="results?.length === 0">
                <h2>{{ "noResultFound" | translate | capitalizeFirstLetter }}</h2>
              </div>
            </div>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
  </mat-dialog-content>
</div>