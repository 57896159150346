import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  {
    path: 'doc/:recordId',
    loadChildren: () =>
      import('./dossier/record.module').then((m) => m.RecordModule),
  },
  {
    path: 'param',
    loadChildren: () =>
      import('./param/param.module').then((m) => m.ParamModule),
  },
  {
    path: 'stats',
    loadChildren: () =>
      import('./stats/stats.module').then((m) => m.StatsModule),
  },
  {
    path: 'ref',
    loadChildren: () =>
      import('./ref/ref.module').then((m) => m.RefModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
