<div style="padding: 24px;">
  <h1 mat-dialog-title>{{ "newRecord" | translate | capitalizeFirstLetter }}</h1>
  <p>
    {{ "newRecordDescr" | translate | capitalizeFirstLetter }}
  </p>
  <br>
  <div mat-dialog-content>
    <form [formGroup]="form">
      <mat-radio-group formControlName="gender">
        <mat-radio-button style="margin-left: 5px;" *ngFor="let gender of genders" [value]="gender.value">{{
          gender.label | translate
          }}</mat-radio-button>
      </mat-radio-group>
      <p>
        <mat-form-field color="accent" appearance="outline" style="width: 100%; margin-top:10Px">
          <mat-label>{{ "yearMonthBirth" | translate | capitalizeFirstLetter }}</mat-label>
          <input autocomplete="off" matInput formControlName="birthdate" type="text" mask="0000 /M0"
            placeholder="AAAA / MM" showMaskTyped="true" placeHolderCharacter="_" />
        </mat-form-field>
      </p>
    </form>
    <p *ngIf="isDateInvalid" style="color: red">{{ "invalidDate" | translate | capitalizeFirstLetter }}</p>
  </div>
  <div mat-dialog-actions style="margin-left: auto">
    <button mat-button [disabled]="!form.valid ||isLoading" (click)="checkDate()" cdkFocusInitial>
      <span *ngIf="!isLoading">{{ "confirm" | translate | capitalizeFirstLetter }}</span>
      <mat-progress-spinner style="display: block; margin-left: auto; margin-right: auto;" *ngIf="isLoading"
        [diameter]="24" [mode]="'indeterminate'" color="primary">
      </mat-progress-spinner>
    </button>
    <button mat-button mat-dialog-close>{{ "cancel" | translate | capitalizeFirstLetter }}</button>
  </div>
</div>