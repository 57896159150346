import { Component, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { NewDossierComponent } from './new-dossier/new-dossier.component';
import { Record } from 'src/app/shared/models/record.model';
import { SearchDocComponent } from './search-record/search-record.component';
import { TutoComponent } from 'src/app/shared/components/tuto/tuto.component';
import { SharedService } from 'src/app/shared/services/shared.service';
import { GraphqlService } from '../core/graphql/graphql.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements  OnDestroy {
  myControl = new UntypedFormControl();

  isLoading = false;

  searchValue: string = '';

  results: Record[];

  etabId: string;


  allDocs: Record[];

  offset = new Subject<string>();

  allLoaded = false;

  emptySearch: boolean;

  routeSub: Subscription;

  isEmpty: boolean;
  isArchivesEmpty: boolean;

  constructor(
    public router: Router,
    route:ActivatedRoute,
    public dialog: MatDialog,
    private _graphql: GraphqlService,
    private _translate: TranslateService,
    private sharedService: SharedService
  ) { 
    this.routeSub = route.params.subscribe(_=>{
      this.refresh()
    })
  }

  private refresh(){
    this.isLoading = true;
    this._graphql.hasRecords()
      .pipe(
        take(1),
        finalize(() => this.isLoading = false)
      )
      .subscribe(
        ({ data }: any) => {
          this.isEmpty = !data.hasRecords;
          this.isArchivesEmpty = !data.hasArchives;
        },
        (error) => {
          this.sharedService.makeToast(
            this._translate.instant("errorOccured"),
            '',
            'danger'
          );
        }
      )
  }

  goToParam() {
    this.router.navigate(['start', 'param']);
  }

  goToStats() {
    this.router.navigate(['start', 'stats']);
  }

  goToRef() {
    this.router.navigate(['start', 'ref']);
  }

  newDossier() {
    const dialogRef = this.dialog.open(NewDossierComponent, {
      data: {
        etabId: this.etabId,
      },
    })
  }

  onSearchRecord(isArchived: boolean) {
    const dialogRef = this.dialog.open(SearchDocComponent, {
      width: '65%',
      minWidth: '300px',
      height: '85%',
      data: {
        etabId: this.etabId,
        isArchived,
      },
    });
    if (isArchived){
      dialogRef.afterClosed().toPromise().then(this.refresh.bind(this));
    }
  }

  onTuto() {
    const dialogRef = this.dialog.open(TutoComponent, {
      panelClass: 'app-full-bleed-dialog',
      width: '85%',
      height: '95%',
      maxWidth: '100%',
      maxHeight: '100%',
      data: {
        isDossier: false,
      },
    });
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }
}
